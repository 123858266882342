<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="mx-n10 my-n8">
        <v-row>
          <v-col md="12">
            <v-btn
              color="secondary"
              dark
              @click="backToShop"
              style="float: right; margin-top: -20px; margin-bottom:15px; border-radius: 0px 0px 0px 0px;"
            >
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="black">
                    mdi-store
                  </v-icon>
                  <v-icon v-bind="attrs" v-on="on" color="black">
                    mdi-undo-variant
                  </v-icon>
                </template>
                <span> Back to Shopping</span>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-card class="mx-auto" max-width="600">
        <material-card
          icon="mdi-receipt"
          icon-small
          color="secondary"
          :title="`Bill No : ` + bill.billNo"
          :subtitle="`Created On: ` + dateFormat(bill.createdAt)"
        >
          <v-card-text>
            <div class="comapny-name">
              {{ companyInfo.companyName }}
            </div>

            <div class="company-detail">ABN : {{ companyInfo.abn }}</div>

            <div class="company-detail">
              <p><span v-html="companyInfo.address"></span></p>
            </div>
            <div class="company-detail my-n4">
              <v-icon small dense class="mt-n1">mdi-phone-classic</v-icon>
              {{ companyInfo.phoneNumber }}
            </div>
          </v-card-text>
          <v-card-text class="my-n4">
            <div class="order-type-text">
              {{ orderTypeText }}
            </div>

            <v-container>
              <v-row>
                <v-col
                  cols="14"
                  sm="8"
                  md="8"
                  style="background-color:whitesmoke"
                  class="bill-details-info"
                >
                  <v-card-text class="mb-n5">
                    <v-row style="font-size:18px; color: black">
                      <v-col cols="6">
                        <div>
                          SUB TOTAL
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                          $
                          {{ formatPrice(amount) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="mb-n5">
                    <v-row style=" font-size:18px; color: black">
                      <v-col cols="6">
                        <div>
                          DELIVERY
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                          $ {{ formatPrice(this.bill.riderAmount) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="mb-n5">
                    <v-row style=" font-size:18px; color: black">
                      <v-col cols="6">
                        <div>
                          EXTRA CHARGE
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                          $ {{ formatPrice(this.bill.extraCharges) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text>
                    <v-row
                      style="font-size:18px; font-weight: bold ; color: primary; text-transform:uppercase"
                    >
                      <v-col cols="6">
                        <div>
                          Net Total
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div>
                          :
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="float-right">
                          $ {{ formatPrice(bill.billTotal) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text></v-col
                >

                <v-col
                  style="background-color:whitesmoke"
                  class="qr-code-info"
                  cols="14"
                  sm="4"
                  md="4"
                >
                  <v-card-text class="mb-n5">
                    <qrcode-vue
                      style="float:right"
                      v-if="bill.billNo"
                      render-as="svg"
                      :value="bill.billNo"
                      size="120"
                      level="H"
                    />
                  </v-card-text>
                </v-col>
              </v-row>

              <v-card-text>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="bill.billProducts"
                    hide-default-footer
                    style="color:black"
                  >
                    <!-- :items-per-page="5" -->
                    <!-- <template #header="{ props: { headers } }">
                    <thead>
                      <tr>
                        <th
                          v-for="header in headers"
                          :key="header.value"
                          style=" font-weight: bold; color:black; font-size:20px; "
                          scope="col"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </thead>
                  </template> -->
                    <template v-slot:[`item.image`]="{ item }">
                      <v-list-item-avatar size="50" v-if="item.image">
                        <v-img :src="item.image" />
                      </v-list-item-avatar>
                      <div v-else class="ml-2">No Image</div>
                    </template>

                    <template v-slot:[`item.quantity`]="{ item }">
                      {{
                        (item.unitQty && item.unitQty > 0
                          ? item.quantity / item.unitQty
                          : item.quantity) +
                          " " +
                          item.unitName
                      }}
                    </template>
                    <template v-slot:[`item.amount`]="{ item }">
                      {{ formatPrice(item.amount) }}
                    </template>

                    <template v-slot:[`item.actualPrice`]="{ item }">
                      {{ formatPrice(item.actualPrice) }}
                    </template>
                  </v-data-table>

                  <!-- <v-btn color="secondary" dark tile @click="customerInfo">
                  <v-card-text class="black--text font-weight-bold">
                    Proceed to Checkout
                  </v-card-text>
                </v-btn> -->
                </v-col>
              </v-card-text>
            </v-container>
          </v-card-text>
        </material-card>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
import Vue from "vue";
Vue.component("qrcode-vue", QrcodeVue);

import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: { MaterialCard, Loading },

  data() {
    return {
      cartGst: 0,
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      orderTypeText: "",
      orderBill: {
        customerId: 0,
        customerName: "",
        custCode: "",
        products: [
          {
            productId: 0,
            quantity: 0,
            instructions: "",
            orderType: 0,
            sizeId: 0,
            addonItems: [],
            ingredients: []
          }
        ],
        change: 0,
        cash: 0,
        card: 0,
        voucher: 0,
        accounts: 0,
        cheque: 0,
        deliveryAddress: "",
        deliveryPostCode: "",
        deliveryContactNo: "",
        isWebOrder: true,
        paidAmount: 0,
        deliveryBoyName: "",
        riderAmount: 0,
        tokenNo: "",
        tabID: "",
        discountReason: "",
        discountTotal: 0,
        orderType: 0
      },

      viewCart: false,
      placeOrderView: false,
      headers: [
        //  { text: "", value: "image" },
        { text: "Item", value: "productDescription" },
        // { text: "Addons", value: "addonItemsName" },
        { text: "Quantity", value: "quantity" },
        { text: "Unit", value: "amount" },
        { text: "Price", value: "actualPrice" }
        // { text: "", value: "delete" }
      ],
      dialogDelete: false,
      editedItem: {
        itemName: "",
        unitPrice: "",
        quantity: "",
        cost: ""
      },
      discount: 0,
      gst: 0,
      bills: {},
      billNo: "",
      orderCartData: [],
      finalBill: [],
      companyInfo: {},
      bill: []
    };
  },

  computed: {
    billNumber() {
      return this.$route.params.id;
    },
    ...mapState(["cartData"]),
    ...mapState(["cartNetCost"]),

    ...mapState(["orderType"]),
    amount() {
      let amount = 0;
      if (this.bill && this.bill.billProducts) {
        amount = this.bill.billProducts.reduce((total, x) => {
          total +=
            x.amount *
            (x.unitQty && x.unitQty > 0 ? x.quantity / x.unitQty : x.quantity);
          return total;
        }, 0);
      }
      return amount;
    }
  },

  created() {
    this.isLoading = true;
    this.getData();

    this.$http.get("/Settings/CompanyInfo").then(response => {
      this.companyInfo = response.data;
    });
  },
  methods: {
    ...mapMutations(["UPDATE_NET_COST"]),
    dateFormat(val) {
      return moment
        .utc(val)
        .local()
        .format("DD/MM/YYYY -  h:mm A");
    },
    getData() {
      this.$http.get(`BillHeader/BillNo/${this.billNumber}`).then(response => {
        if (response.data.success) {
          this.bill = response.data.data;
          console.log(this.bill);
          this.cartGst = this.bill.billTotal / 10;

          if (this.bill.orderType == 0) {
            this.orderTypeText = "Pick Up";
          }
          if (this.bill.orderType == 1) {
            this.orderTypeText = "Delivery";
          }
          if (this.bill.orderType == 2) {
            this.orderTypeText = "Dine In";
          }

          this.isLoading = false;
        }
      });
    },

    getBillNo() {
      this.isLoading = true;
      this.$http.get("/BillHeader").then(response => {
        this.bills = response.data.data;
        var lastBillNo = Math.max.apply(
          Math,
          this.bills.map(function(o) {
            return o.billNo;
          })
        );

        if (this.bills.length == 0) {
          this.billNo = 1;
        } else {
          this.billNo = lastBillNo + 1;
        }
        this.isLoading = false;
        this.placeOrder();
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    backToShop() {
      //  console.log("back");
      this.cartData.splice(0, this.cartData.length);

      this.UPDATE_NET_COST(0);
      this.$router.push("/");
      window.localStorage.clear();
    }
  }
};
</script>

<style>
.comapny-name {
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  font-size: 30px;
  color: black;
  font-weight: bold;
}

.company-detail {
  padding: 2px;
  text-align: center;
  font-size: 15px;
  color: black;
}
.order-type-text {
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px;
  color: black;
  font-weight: bold;
}
</style>
